import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import "../components/404.css"

const NotFoundPage = () => (
  <Layout>
    <div className="container">
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <h1 style={{ paddingBottom: `50px`, fontSize: `62px` }}>404</h1>
      <p>The page you are looking for doesn&#39;t exist.</p>
      <p style={{ paddingBottom: `50px` }}>
        Try to refresh your browser window or check your network connection.
      </p>
      <Link to="/">
        <p style={{ paddingBottom: `10%` }} className="back-link">
          Go back to the homepage
        </p>
      </Link>
      <br></br>
    </div>
  </Layout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
